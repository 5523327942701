import { useCallback, useEffect, useState } from 'react';

import { useResizeListener } from '@common/hooks/useResizeListener';

import type { RefObject } from 'react';

export default function useCarouselScroll(
  container: RefObject<HTMLElement>,
  scrollableElement: RefObject<HTMLElement>
) {
  const [scrollPercent, setScrollPercent] = useState(0);
  const [isScrollable, setIsScrollable] = useState(false);

  const getScrollPercent = useCallback(() => {
    if (!scrollableElement.current) {
      return 0;
    }
    const { clientWidth, scrollLeft, scrollWidth } = scrollableElement?.current;
    const scrolledPercent = Math.round((scrollLeft * 100.0) / (scrollWidth - clientWidth));
    return scrolledPercent;
  }, [scrollableElement]);

  const getIsScrollable = useCallback(() => {
    if (container.current && scrollableElement.current) {
      return scrollableElement.current.scrollWidth > container.current.offsetWidth;
    }
    return false;
  }, [container, scrollableElement]);

  const scrollLeft = useCallback(() => {
    if (!container.current || !scrollableElement.current) {
      return;
    }
    const { offsetWidth } = container.current;
    scrollableElement.current.scroll({
      left: scrollableElement.current.scrollLeft - offsetWidth,
      behavior: 'smooth'
    });
  }, [container, scrollableElement]);

  const scrollRight = useCallback(() => {
    if (!container.current || !scrollableElement.current) {
      return;
    }
    const { offsetWidth } = container.current;
    scrollableElement.current.scroll({
      left: scrollableElement.current.scrollLeft + offsetWidth,
      behavior: 'smooth'
    });
  }, [container, scrollableElement]);

  useResizeListener(() => {
    setScrollPercent(getScrollPercent());
    setIsScrollable(getIsScrollable());
  });

  useEffect(() => {
    setScrollPercent(getScrollPercent());
    setIsScrollable(getIsScrollable());
    scrollableElement?.current?.addEventListener('scroll', () => {
      setScrollPercent(getScrollPercent());
    });
  }, [getScrollPercent, getIsScrollable, scrollableElement]);

  return {
    isScrollable,
    scrollLeft,
    scrollPercent,
    scrollRight
  };
}
