/** @jsxImportSource @emotion/react */
import Image from 'next/image';
import { useRef } from 'react';

import { noScrollbarStyle } from '@common/cssUtilities';
import useCarouselScroll from '@common/hooks/useCarouselScroll';
import { buildMediaUrl } from '@common/utlis';
import ArrowButton, { glassArrowCss } from '@stories/atoms/ArrowButton';
import { SectionStyled } from '@stories/atoms/Section/Section';
import { mediaDown, mq } from '@stories/theming/settings';

import type { LinkStripeFragment } from '@gql/hygraph/graphql';

const LinkStripe: React.FC<LinkStripeFragment> = ({ links, headline, sectionStyle }) => {
  const containerRef = useRef<HTMLUListElement>(null);
  const { isScrollable, scrollLeft, scrollPercent, scrollRight } = useCarouselScroll(
    containerRef,
    containerRef
  );

  if (!links || links?.length <= 0) {
    return null;
  }

  return (
    <SectionStyled spacingSize="short" headline={headline || undefined} {...sectionStyle}>
      <div css={{ position: 'relative' }}>
        {isScrollable && (
          <div
            css={(t) => ({
              left: '50%',
              maxWidth: t.section.width.wide,
              padding: '0 var(--offset)',
              position: 'absolute',
              top: '50%',
              transform: 'translate(-50%, -50%)',
              width: '100%',
              zIndex: 1,
              [mediaDown('tablet')]: {
                display: 'none'
              }
            })}
          >
            <ArrowButton
              cssProps={{
                ...glassArrowCss,
                left: '0',
                top: '-22px',
                position: 'absolute',
                transform: 'rotate(180deg)'
              }}
              onClick={scrollLeft}
              disabled={scrollPercent === 0 ? true : false}
            />
            <ArrowButton
              cssProps={{ ...glassArrowCss, right: '0', position: 'absolute', top: '-22px' }}
              onClick={scrollRight}
              disabled={scrollPercent === 100 ? true : false}
            />
          </div>
        )}
        <ul
          ref={containerRef}
          css={mq({
            display: 'flex',
            filter: '',
            gap: ['1.5rem', null, '3rem'],
            overflow: 'auto',
            padding: '0 var(--offset)',
            ...noScrollbarStyle,
            ...(!isScrollable && { justifyContent: 'center' })
          })}
        >
          {links.map(({ image }) => (
            <li key={image?.url} css={{ height: '4rem', position: 'relative' }}>
              <Image
                css={
                  {
                    display: 'block',
                    filter: 'grayscale(1)',
                    position: 'relative !important' as unknown as undefined,
                    width: 'auto !important'
                  } as const
                }
                src={image?.url ? buildMediaUrl(image?.url) : ''}
                alt={image?.alt || ''}
                fill
                unoptimized
              />
            </li>
          ))}
        </ul>
      </div>
    </SectionStyled>
  );
};

export default LinkStripe;
